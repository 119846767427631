import React from "react"
import { Link } from "gatsby"

const Breadcrumbs = ({ data }) => {
  if (!data) return null
  return (
    <div className="breadcrumbs-wrap">
      <div className="container">
        <div className="breadcrumbs">
          {data.map((item, index) => {
            {
              return (
                <>
                  {index > 0 && <span className="separator">&rarr;</span>}
                  <Link to={item.url} title={item.text} key={index}>
                    <p dangerouslySetInnerHTML={{ __html: item.text }} />
                  </Link>
                </>
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default Breadcrumbs
