/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import DefaultOgImage from "./seo/og_image.jpg" // If this jpg is in the same folder as .svg, gatsby-plugin-react-svg throws an error Unexpected character

function SEO({
               title,
               description,
               lang,
               ogImage,
               ogTitle,
               ogDescription,
               twitterImage,
               twitterTitle,
               twitterDescription,
               canonicalUrl,
               hide,
               meta,
               pageUri
             }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  )

  const siteUrl = site.siteMetadata?.siteUrl || "https://transcyko.com"

  const defaultDescription = description || site.siteMetadata?.description
  const defaultTitle = title || site.siteMetadata?.title
  const defaultLang = lang || "en"
  const uri = pageUri ? siteUrl + pageUri : '';

  const defaultOgImage = ogImage || siteUrl + DefaultOgImage
  const defaultOgTitle = ogTitle || defaultTitle
  const defaultOgDescription = ogDescription || defaultDescription

  const defaultTwitterImage = twitterImage || siteUrl + DefaultOgImage
  const defaultTwitterTitle = twitterTitle || defaultTitle
  const defaultTwitterDescription = twitterDescription || defaultDescription

  return (
    <Helmet
      htmlAttributes={{
        defaultLang
      }}
      title={defaultTitle}
      meta={[
        {
          name: `robots`,
          content: hide === true ? "noindex, nofollow" : "index, follow"
        },
        {
          name: `description`,
          content: defaultDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:title`,
          content: defaultOgTitle
        },
        {
          property: `og:description`,
          content: defaultOgDescription
        },
        {
          property: `og:image`,
          content: defaultOgImage
        },
        {
          property: `og:url`,
          content: uri
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:title`,
          content: defaultTwitterTitle
        },
        {
          name: `twitter:description`,
          content: defaultTwitterDescription
        },
        {
          name: `twitter:image`,
          content: defaultTwitterImage
        }
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default SEO
