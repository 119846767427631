import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import FaxIcon from "../images/fax.svg"
import LocationIcon from "../images/location.svg"
import MailIcon from "../images/mail.svg"
import PhoneIcon from "../images/phone.svg"
import Logo from "../images/logo.svg"
import LogoFooter from "../images/logo-footer.svg"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        generalSettings {
          title
        }
      }

      wpMenu(slug: { eq: "footer-menu" }) {
        menuItems {
          nodes {
            url
            label
          }
        }
      }
    }
  `)

  return (
    <>
      <div className="footer">
        <div className="container columns">
          <div className="column">
            <p className="footer-title">Products</p>
            <ul>
              {data.wpMenu?.menuItems?.nodes.map((item, index) => {
                return (
                  <li key={index}>
                    <Link title={item.label} to={item.url}>
                      {item.label}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="column">
            <Link to="/about-us" className="footer-title">About Us</Link>
            <Link to="/production" className="footer-title">Production</Link>
            <Link to="/news-events" className="footer-title">News</Link>
            <Link to="/contact" className="footer-title">Contact</Link>
          </div>
          <div className="column">
            <p className="footer-title">
              <Logo width={220} />
            </p>
            <div className="contact-line address">
              <div className="line-icon">
                <LocationIcon />
              </div>
              <span>
                <a
                  href="https://g.page/transcyko?share"
                  title="Transcyko Location Google Maps"
                  target="_blank"
                >
                  Tainan, Taiwan 74160
                </a>
              </span>
            </div>
            <div className="contact-line phone">
              <div className="line-icon">
                <PhoneIcon />
              </div>
              <span>
                <a href="tel:+886-6-5818117">+886-6-5818117</a>
              </span>
            </div>
            <div className="contact-line fax">
              <div className="line-icon">
                <FaxIcon />
              </div>
              <span>
                <a href="fax:+886-6-5819117">+886-6-5819117</a>
              </span>
            </div>
            <div className="contact-line email">
              <div className="line-icon">
                <MailIcon />
              </div>
              <span>
                <a href="mailto:evachen@transcyko.com">evachen@transcyko.com</a>
              </span>
            </div>
          </div>
          <div class="column">
              <LogoFooter className="logo-footer" style={{
                marginTop: "2rem"
              }} />
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <p>Transmission Machinery Co., Ltd.</p>
          <div className="globalsense-text">
            Designed by <a href="https://en.globalsense.com.tw/" className="globalsense-link" title="GlobalSense" target="_blank">GlobalSense</a>
            <span> ‧ </span>
            <a href="https://globalsense.com.tw/" className="globalsense-link" title="台中響應式網頁設計" target="_blank">網頁設計 </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
