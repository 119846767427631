import React, { useState, useEffect } from "react"

const Popup = () => {
  const [popupShow, setPopup] = useState(false)

  const checkScrollTop = () => {
    if (typeof window === "undefined" || localStorage.getItem("hide-popup") !== null) return
    if (!popupShow && window.pageYOffset > window.innerHeight) {
        setPopup(true)
    } else if (popupShow && window.pageYOffset <= window.innerHeight) {
      setPopup(false)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", checkScrollTop)
    }
    return () => {
      window.removeEventListener("scroll", checkScrollTop)
    }
  }, [])

  return (
    <div className={popupShow ? "active popup" : "popup"}>
      <div
        className="popup-close"
        onClick={() => {
          window.removeEventListener("scroll", checkScrollTop)
          localStorage.setItem("hide-popup", 1)
          setPopup(false)
        }}
      >
        &times;
      </div>
      <div className="white-box">
        <div className="flag us"></div>
        <div>
          <p className="bold">USA & Europe Distributors Wanted</p>
          <p>
            If you are experienced in gearboxes and speed reducers for heavy industry, we would love to hear from you.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Popup
