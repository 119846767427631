import React from "react"
import DownArrow from "../images/down-arrow.svg"

const GoUp = ({ active }) => {
  return (
    <div
      className={active ? "scroll-top active" : "scroll-top"}
      onClick={() => window.scrollTo({
        top: 0,
        behavior: "smooth",
      })}
    >
      <DownArrow></DownArrow>
    </div>
  )
}

export default GoUp
