import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Logo from "../images/logo.svg"
import OpenDown from "../images/down-arrow.svg"
import { Link } from "gatsby"
import ContactIcon from "../images/contact.svg"
import GoUp from "./go-up"

const Header = () => {
  const [menu, toggleMenu] = useState("")
  const [headerSmall, setHeaderSmall] = useState(false)
  const [activeMenu, setActiveMenu] = useState(null)
  
  const data = useStaticQuery(graphql`
    query {
      wp {
        generalSettings {
          title
        }
      }
      wpMenu(slug: { eq: "main-menu" }) {
        menuItems {
          nodes {
            id
            url
            label
            parentId
            cssClasses
            target
          }
        }
      }
    }
  `)

  const setActiveMenuWrapper = index => {
    if (activeMenu !== null) index = null
    setActiveMenu(index)
  }

  useEffect(() => {
    if (typeof window === "undefined") return
    const checkScrollTop = () => {
      if (!headerSmall && window.pageYOffset > 60) {
        setHeaderSmall(true)
      } else if (headerSmall && window.pageYOffset <= 60) {
        setHeaderSmall(false)
      }
    }
    window.addEventListener("scroll", checkScrollTop)
    return () => {
      window.removeEventListener("scroll", checkScrollTop)
    }
  }, [headerSmall])

  const new_menu = []
  const old_menu = data.wpMenu?.menuItems?.nodes
  old_menu.forEach(item => {
    item.submenu = []
    if (item.parentId) {
      const submenu = item
      old_menu.forEach(item => {
        if (item.id == submenu.parentId) {
          item.submenu.push(submenu)
        }
      })
    } else {
      new_menu.push(item)
    }
  })

  return (
    <>
    <header className={headerSmall && "header-small"}>
      <div className="container">
        <Link className="logo" title="Transcyko Homepage" to="/">
          <Logo></Logo>
        </Link>
        <Link
          to="/contact"
          className="contact-icon"
          title="Transcyko Contact Page"
        >
          <ContactIcon></ContactIcon>
        </Link>

        <div
          className={`hamburger ${menu ? "active" : ""}`}
          onClick={() => toggleMenu(!menu)}
        >
          <span></span>
        </div>
        {new_menu && (
          <nav className={`main-menu ${menu ? "active" : ""}`}>
            <ul>
              {new_menu.map((item, index) => {
                return (
                  <li key={index} className={item.cssClasses}>
                    <Link to={item.url} title={item.label} target={item.target}>
                      <span>{item.label}</span>
                    </Link>
                    {item.submenu && item.submenu.length > 0 && (
                      <>
                        <div
                          className={`submenu-open ${
                            activeMenu === index && "opened"
                          }`}
                          onClick={() => setActiveMenuWrapper(index)}
                        >
                          <OpenDown></OpenDown>
                        </div>
                        <ul className="submenu">
                          {item.submenu.map((item, index) => {
                            return (
                              <li key={index}>
                                <Link to={item.url} title={item.label}>
                                  <span>{item.label}</span>
                                </Link>
                              </li>
                            )
                          })}
                        </ul>
                      </>
                    )}
                  </li>
                )
              })}
            </ul>
          </nav>
        )}
      </div>
    </header>
    <GoUp active={headerSmall}></GoUp>
    </>
  )
}
export default Header
