/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import "../css/index.sass"

import Header from "./header"
import Footer from "./footer"
import Popup from "./popup"
import Breadcrumbs from "./breadcrumbs"

const Layout = ({ children, className, breadcrumbs }) => {
  return (
    <>
      <Header />
      {breadcrumbs && <Breadcrumbs data={breadcrumbs} />}
      <main className={className}>{children}</main>
      <Popup></Popup>
      <Footer />
    </>
  )
}

export default Layout
